<template>
    <v-container fluid class="secondary-font px-0">
        <v-row no-gutters>
            <v-col v-for="(arena, index) in arenas" :key="index" cols="12" md="4" class="my-3 py-3 px-2">
                <v-card
                    class="rounded-lg"
                    @click="openMap(arena)"
                >
                    <v-row class="mx-0 px-0">
                        <v-col cols="12">
                            <gmap-map
                                :center="getMarker(arena)"
                                :zoom="17"
                                :options="options"
                                style="width: 100%; height: 200px;"
                            >
                                <gmap-marker
                                    :position="getMarker(arena)"
                                    :clickable="true"
                                />
                            </gmap-map>
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 px-0" style="height: 100px;">
                        <v-col cols="12" class="text-h6 font-weight-bold">
                            <v-icon
                                color="orange"
                            >
                                mdi-basketball-hoop-outline
                            </v-icon>
                            {{ arena.name }}
                        </v-col>
                    </v-row>
                    <v-row class="mx-0 px-0" style="height: 110px;">
                        <v-col cols="12" class="text-subtitle-1 font-weight-bold">
                            <v-icon
                                color="orange"
                            >
                                mdi-map-marker-outline
                            </v-icon>
                            {{ arena.address }}
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import arenaService from '@/services/arena'

    export default {
        data() {
            return {
                center: { lat: 37.924458, lng: 23.705159 },
                options: {
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    draggable: false
                },
                arenas: [],
                page: 1,
                size: 1000,
                totalPages: 1
            }
        },
        created() {
            this.fetchAllArenas()
        },
        methods: {
            openMap(arena) {
                window.open(`https://www.google.com/maps/place/${arena.coordinates}`, '_self', 'noreferrer')
            },
            fetchAllArenas() {
                arenaService.getArenas(this.page, this.size).then(resp => {
                    this.arenas = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            getMarker(arena) {
                const coor = arena.coordinates.split(',')
                return { lat: Number(coor[0]), lng: Number(coor[1]) }
            }
        }
    }
</script>
